<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.817" x2="0.392" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_drink" transform="translate(-598 -2768)">
    <g data-name="Group 422" id="Group_422" transform="translate(0 -13)">
     <rect :fill="background" data-name="Rectangle 213" height="183" id="Rectangle_213" transform="translate(598 2781)" width="183"/>
     <path d="M73.924,8.481A4.968,4.968,0,0,0,70.412,0H4.977A4.968,4.968,0,0,0,1.465,8.481L32.983,40V68.321H24.737a5.89,5.89,0,0,0-5.89,5.89,1.178,1.178,0,0,0,1.178,1.178H55.364a1.178,1.178,0,0,0,1.178-1.178,5.89,5.89,0,0,0-5.89-5.89H42.406V40ZM65.342,7.068l-7.068,7.068H17.116L10.048,7.068H65.342Z" data-name="Icon awesome-glass-martini-alt" fill="url(#linear-gradient)" id="Icon_awesome-glass-martini-alt" transform="translate(652 2835)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>